/** @jsx jsx */
import { graphql } from "gatsby"

import { jsx } from "@trueskin-web/theme"

import SEODetails from "../block-library/components/seo-details"
import Layout from "../components/layout"
import Legal from "../templates/legal"

const DataProtectionPage = ({
  data: {
    strapi: { dataProtectionPage },
  },
}) => (
  <Layout title={dataProtectionPage.title}>
    <SEODetails
      title={dataProtectionPage.title}
      {...dataProtectionPage.seoDetails}
    />

    <Legal
      title={dataProtectionPage.title}
      subtitle={dataProtectionPage.subtitle}
      content={dataProtectionPage.content}
      blocks={dataProtectionPage.blocks}
    />
  </Layout>
)

export const query = graphql`
  {
    strapi {
      dataProtectionPage {
        title
        seoDetails {
          ...SEODetailsFragment
        }
        blocks {
          __typename
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
        }
        subtitle
        content
      }
    }
  }
`

export default DataProtectionPage
